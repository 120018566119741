<template>
  <div>
    <div class="action-bar">
      <a-button
        type="primary"
        :loading="exporting"
        @click="handleExport"
      >
        导出
      </a-button>
    </div>
    <div class="data-table">
      <a-table
        :loading="loading"
        :columns="columns"
        :data-source="data"
        :pagination="false"
      >
        <div slot="dealerInfo" slot-scope="text, record">
          <p>
            <a @click="jumpToPage(record)">
              经销商名称：{{ record.dealer_name || '--' }}
            </a>
          </p>
          <p>归属品牌：{{ record.principal_names || '--' }}</p>
        </div>
        <div slot="videoInfo" slot-scope="text, record">
          <p>
            <a :href="record.product_url" target="_blank">
              视频名称：{{ record.text || '--' }}
            </a>
          </p>
          <p>发布平台：{{ record.platform_name || '--' }}</p>
          <p>平台帐号：{{ record.author_name || '--' }}</p>
          <p>发布时间：{{ record.ctime || '--' }}</p>
          <p>统计时间：{{ record.time || '--' }}</p>
        </div>
        <div slot="usageInfo" slot-scope="text, record">
          <p>生成成片：{{ record.aweme_total }}</p>
          <p>发布视频(失败）：{{ record.publish_fail_total }} <a @click="openPublishModal(record)">[详情]</a></p>
          <p>发布视频(成功）：{{ record.publish_total }}</p>
        </div>
        <div slot="transformEffect" slot-scope="text, record">
          <p>视频播放次数：{{ record.play_volume }}</p>
          <p>视频点赞数：{{ record.digg_count }}</p>
          <p>视频分享数：{{ record.share_count }}</p>
          <p>视频评论数：{{ record.comment_count }}</p>
        </div>
      </a-table>
      <base-pagination
        :currentPage="pagination.page_num"
        :pageSize="pagination.page_size"
        :total="pagination.total"
        @onChange="handlePaginationChange"
        @onShowSizeChange="handlePaginationChange"
      />
    </div>

    <!-- 发布视频（失败）弹框 -->
    <publish-modal
      :visible="visible"
      :loading="loading2"
      :data="data2"
      :pagination="pagination2"
      @cancel="handleCancel"
      @pagination-change="handlePagination2Change"
    />
  </div>
</template>

<script>
import PublishModal from '../components/PublishModal'
import { downloadExcel } from '@/utils'
import templateApi from '@/api/templateAnalysis'

const columns = [
  {
    title: '经销商信息',
    key: 'dealerInfo',
    scopedSlots: { customRender: 'dealerInfo' }
  },
  {
    title: '视频信息',
    key: 'videoInfo',
    scopedSlots: { customRender: 'videoInfo' }
  },
  {
    title: '模板使用信息',
    key: 'usageInfo',
    scopedSlots: { customRender: 'usageInfo' }
  },
  {
    title: '模板转化效果',
    key: 'transformEffect',
    scopedSlots: { customRender: 'transformEffect' }
  }
]

export default {
  name: 'usageDetail',
  components: {
    PublishModal
  },
  data () {
    return {
      loading: false,
      columns,
      data: [],
      pagination: {
        page_num: 1,
        page_size: 10,
        total: 0
      },
      exporting: false,
      visible: false,
      loading2: false,
      data2: [],
      pagination2: {
        page_num: 1,
        page_size: 10,
        total: 0
      }
    }
  },
  created () {
    this.fetchUsageDetailList()
  },
  methods: {
    // 处理分页改变
    handlePaginationChange (page_num, page_size) {
      this.pagination.page_num = page_num
      this.pagination.page_size = page_size
      this.fetchUsageDetailList()
    },
    // 获取使用详情列表
    async fetchUsageDetailList () {
      this.loading = true
      try {
        const { module_id, platform, start_time, end_time } = this.$route.query
        const { page_num, page_size } = this.pagination
        const params = {
          module_id,
          platform,
          start_time,
          end_time,
          page_num,
          page_size
        }
        const res = await templateApi.fetchUsageDetailList(params)

        if (res.code === 200) {
          this.data = res.data.list.map((item, index) => {
            return { ...item, key: index }
          })
          this.pagination.total = res.data.total
          this.loading = false
        } else {
          this.$message.error(res.msg || res.errmsg)
          this.loading = false
        }
      } catch (e) {
        console.log(e)
        this.loading = false
      }
    },
    // 处理导出
    handleExport () {
      this.exportUsageDetail()
    },
    // 导出使用详情
    async exportUsageDetail () {
      this.exporting = true
      try {
        const { module_id, platform, start_time, end_time } = this.$route.query
        const params = {
          module_id,
          platform,
          start_time,
          end_time  
        }
        const res = await templateApi.exportUsageDetail(params)

        downloadExcel(res, '模板使用详情')        
        this.exporting = false
      } catch (e) {
        console.log(e)
        this.exporting = false
      }
    },
    // 打开发布视频(失败）详情
    openPublishModal (record) {
      this.visible = true
      this.selectedRecord = record
      this.pagination2.page_num = 1
      this.fetchUsageDetailPublishFailList()
    },
    //
    handlePagination2Change (page_num, page_size) {
      this.pagination2.page_num = page_num
      this.pagination2.page_size = page_size
      this.fetchUsageDetailPublishFailList()
    },
    // 获取发布视频失败详情列表(使用详情的)
    async fetchUsageDetailPublishFailList () {
      this.loading2 = true
      try {
        const { module_id, platform, start_time, end_time } = this.$route.query
        const { dealer_id } = this.selectedRecord
        const { page_num, page_size } = this.pagination2
        const params = {
          module_id,
          platform,
          start_time,
          end_time,
          dealer_id,
          page_num,
          page_size
        }
        const res = await templateApi.fetchUsageDetailPublishFailList(params)

        if (res.code === 200) {
          this.data2 = res.data.list.map((item, index) => {
            return { ...item, key: index }
          })
          this.pagination2.total = res.data.total
          this.loading2 = false
        } else {
          this.$message.error(res.msg || res.errmsg)
          this.loading2 = false
        }
      } catch (e) {
        console.log(e)
        this.loading2 = false
      }
    },
    //
    handleCancel () {
      this.visible = false
      this.data2 = []
    },
    // 跳转页面
    jumpToPage (record) {
      this.$router.push({
        name: 'dealerDetail',
        query: {
          dealer_id: record.dealer_id
        }
      })  
    }
  }
}
</script>

<style lang="less" scoped>
.action-bar {
  text-align: right;
}

.data-table {
  margin: 10px 0 0;
}
</style>
